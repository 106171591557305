export const state = () => ({
  hasSpecifications: false,
  hasGeometries: false,
  hasManuals: false
})

export const mutations = {
  statusSpecifications (state, status) {
    state.hasSpecifications = status
  },
  statusGeometries (state, status) {
    state.hasGeometries = status
  },
  statusManuals (state, status) {
    state.hasManuals = status
  }
}

export const getters = {
  getSpecifications (state) {
    return state.hasSpecifications
  },
  getGeometries (state) {
    return state.hasGeometries
  },
  getManuals (state) {
    return state.hasManuals
  }
}
