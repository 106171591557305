import Pusher from 'pusher-js'

Pusher.logToConsole = process.env.NODE_ENV === 'development'

export default function ({ $config }, inject) {
  if ($config.PUSHER_APP_ID && $config.PUSHER_KEY && $config.PUSHER_SECRET) {
    const pusher = new Pusher($config.PUSHER_KEY, {
      appId: $config.PUSHER_APP_ID,
      secret: $config.PUSHER_SECRET,
      cluster: 'us3',
      encrypted: true
    })

    inject('pusher', pusher)
  }
}
