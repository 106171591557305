import { v4 as uuidv4, validate as uuidValidate } from 'uuid'

export default function ({ app, route, store, $config }) {
  // If request path are from index, ignore it
  if (['/', ''].includes(route.path)) {
    return
  }

  const cartCookie = app.$cookies.get($config.CART_COOKIE_NAME)
  const createCookieFor = (locale, cartToken) =>
    app.$cookies.set($config.CART_COOKIE_NAME, cartToken, {
      path: `/${store.state.country}-${locale}`,
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'lax'
    })

  if (!cartCookie || (cartCookie && !uuidValidate(cartCookie.split('_')[1]))) {
    const cartToken = `${store.state.country}_${uuidv4()}`

    // default cart cookie (english)
    createCookieFor('en', cartToken)

    // localized cart cookie
    if (store.state.locale !== 'en') {
      createCookieFor(store.state.locale, cartToken)
    }
  }
}
