import { sortBy, groupBy } from 'lodash'

export const state = () => ({
  locales: [],
  defaultLocales: [],
  hasClickoutSide: false,
  defaultContry: null,
  defaultContryName: ''
})

export const actions = {
  handleClickOutside ({ commit }, result) {
    commit('SET_LOCALE_CLICK_OUTSIDE', result)
  },
  handleLocaleUpdating ({ commit, state }, result) {
    commit('SET_LOCALES', state.defaultLocales)
  },
  async fetchLocales ({ commit, rootGetters }) {
    await this.$axios
      .get('/api/sites')
      .then(({ data }) => {
        const locales = data

        const country = locales.find((x) => x.slug === rootGetters.getCountry)

        commit('SET_DEFAULT_COUNTRY', country)
        commit('SET_LOCALES', locales)
      })
      .catch((e) => {
        console.error('Error: ', e)
      })
  }
}

export const mutations = {
  SET_LOCALE_CLICK_OUTSIDE (state, result) {
    state.hasClickoutSide = result
  },
  SET_DEFAULT_COUNTRY (state, country) {
    state.defaultContry = country
    state.defaultContryName = country ? country.country.name : ''
  },
  SET_LOCALES (state, locales) {
    state.locales = locales
    state.defaultLocales = locales
    // state.europeCountries.forEach(element => state.locales.push(element))
    state.locales = sortBy(state.locales, 'id')
    state.locales = groupBy(
      state.locales.filter((region) => {
        return region.settings.under_maintenance === false
      }),
      'country.region'
    )

    // state.locales = groupBy(state.locales.filter((region) => {
    //   return region.country.region !== 'Europe'
    // }), 'country.region')
  }
}

export const getters = {
  getLocales (state) {
    return state.locales
  },
  getHasClickoutSide (state) {
    return state.hasClickoutSide
  },
  getDefaultLocales (state) {
    return state.defaultLocales
  },
  getDefaultContry (state) {
    return state.defaultContry
  },
  getDefaultContryName (state) {
    return state.defaultContryName
  }
}
