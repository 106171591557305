export const state = () => ({
  forceReload: false
})

export const actions = {
  setForceReload ({ commit }, bool) {
    commit('SET_FORCE_RELOAD', bool)
  }
}

export const mutations = {
  SET_FORCE_RELOAD (state, forceReload) {
    state.forceReload = forceReload
  }
}

export const getters = {
  getForceReload (state) {
    return state.forceReload
  }
}
