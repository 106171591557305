// plugins/priceFormatter.js

export default function ({ app, store }, inject) {
    // Retrieve currency and placement from the store or use defaults
    const currency = (store && store.state && store.state.currentSite && store.state.currentSite.default_currency_token)
        ? store.state.currentSite.default_currency_token
        : "$";
  
    const placement = (store && store.state && store.state.currentSite && store.state.currentSite.default_currency_place)
        ? store.state.currentSite.default_currency_place
        : "left";
  
    // Function to format the price with currency placement
    const formatPrice = (price) => {
        if (placement === 'right') {
            return `${price} ${currency}`; 
        } else {
            return `${currency} ${price}`;
        }
    };

    // Inject the formatPrice function into Vue instances, components, and context
    inject('formatPrice', formatPrice);
}
  
  