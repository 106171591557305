export const NotFound = () => import('../../components/404NotFound.vue' /* webpackChunkName: "components/not-found" */).then(c => wrapFunctional(c.default || c))
export const AboutHeroSection = () => import('../../components/AboutHeroSection.vue' /* webpackChunkName: "components/about-hero-section" */).then(c => wrapFunctional(c.default || c))
export const AboutNavSection = () => import('../../components/AboutNavSection.vue' /* webpackChunkName: "components/about-nav-section" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const BagIcon = () => import('../../components/BagIcon.vue' /* webpackChunkName: "components/bag-icon" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BannerWrapper = () => import('../../components/BannerWrapper.vue' /* webpackChunkName: "components/banner-wrapper" */).then(c => wrapFunctional(c.default || c))
export const BaseTab = () => import('../../components/BaseTab.vue' /* webpackChunkName: "components/base-tab" */).then(c => wrapFunctional(c.default || c))
export const BecomeContactUs = () => import('../../components/BecomeContactUs.vue' /* webpackChunkName: "components/become-contact-us" */).then(c => wrapFunctional(c.default || c))
export const BecomeFaqs = () => import('../../components/BecomeFaqs.vue' /* webpackChunkName: "components/become-faqs" */).then(c => wrapFunctional(c.default || c))
export const BecomeHeroSection = () => import('../../components/BecomeHeroSection.vue' /* webpackChunkName: "components/become-hero-section" */).then(c => wrapFunctional(c.default || c))
export const BecomeSubHeroSection = () => import('../../components/BecomeSubHeroSection.vue' /* webpackChunkName: "components/become-sub-hero-section" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const ColorBall = () => import('../../components/ColorBall.vue' /* webpackChunkName: "components/color-ball" */).then(c => wrapFunctional(c.default || c))
export const CuratorWidget = () => import('../../components/CuratorWidget.vue' /* webpackChunkName: "components/curator-widget" */).then(c => wrapFunctional(c.default || c))
export const Datepicker = () => import('../../components/Datepicker.vue' /* webpackChunkName: "components/datepicker" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const ErrorBag = () => import('../../components/ErrorBag.vue' /* webpackChunkName: "components/error-bag" */).then(c => wrapFunctional(c.default || c))
export const ErrorsContainer = () => import('../../components/ErrorsContainer.vue' /* webpackChunkName: "components/errors-container" */).then(c => wrapFunctional(c.default || c))
export const FlyoutMenu = () => import('../../components/FlyoutMenu.vue' /* webpackChunkName: "components/flyout-menu" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterKeewayLogo = () => import('../../components/FooterKeewayLogo.vue' /* webpackChunkName: "components/footer-keeway-logo" */).then(c => wrapFunctional(c.default || c))
export const GlobeIcon = () => import('../../components/GlobeIcon.vue' /* webpackChunkName: "components/globe-icon" */).then(c => wrapFunctional(c.default || c))
export const HeaderSection = () => import('../../components/HeaderSection.vue' /* webpackChunkName: "components/header-section" */).then(c => wrapFunctional(c.default || c))
export const ImgLazyLoader = () => import('../../components/ImgLazyLoader.vue' /* webpackChunkName: "components/img-lazy-loader" */).then(c => wrapFunctional(c.default || c))
export const ImgLazyPlaceholder = () => import('../../components/ImgLazyPlaceholder.vue' /* webpackChunkName: "components/img-lazy-placeholder" */).then(c => wrapFunctional(c.default || c))
export const InputCountry = () => import('../../components/InputCountry.vue' /* webpackChunkName: "components/input-country" */).then(c => wrapFunctional(c.default || c))
export const InputPlaces = () => import('../../components/InputPlaces.vue' /* webpackChunkName: "components/input-places" */).then(c => wrapFunctional(c.default || c))
export const KeewayGroupLogo = () => import('../../components/KeewayGroupLogo.vue' /* webpackChunkName: "components/keeway-group-logo" */).then(c => wrapFunctional(c.default || c))
export const KeewayLogo = () => import('../../components/KeewayLogo.vue' /* webpackChunkName: "components/keeway-logo" */).then(c => wrapFunctional(c.default || c))
export const LocaleSelector = () => import('../../components/LocaleSelector.vue' /* webpackChunkName: "components/locale-selector" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MBPItalianFlag = () => import('../../components/MBPItalianFlag.vue' /* webpackChunkName: "components/m-b-p-italian-flag" */).then(c => wrapFunctional(c.default || c))
export const MobileGallery = () => import('../../components/MobileGallery.vue' /* webpackChunkName: "components/mobile-gallery" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuIcon = () => import('../../components/MobileMenuIcon.vue' /* webpackChunkName: "components/mobile-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigation = () => import('../../components/MobileNavigation.vue' /* webpackChunkName: "components/mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const ModalWrapper = () => import('../../components/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMenu = () => import('../../components/MyAccountMenu.vue' /* webpackChunkName: "components/my-account-menu" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NewsHeaderSection = () => import('../../components/NewsHeaderSection.vue' /* webpackChunkName: "components/news-header-section" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../components/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const OpenMap = () => import('../../components/OpenMap.vue' /* webpackChunkName: "components/open-map" */).then(c => wrapFunctional(c.default || c))
export const OurStorySection = () => import('../../components/OurStorySection.vue' /* webpackChunkName: "components/our-story-section" */).then(c => wrapFunctional(c.default || c))
export const PaymentOptions = () => import('../../components/PaymentOptions.vue' /* webpackChunkName: "components/payment-options" */).then(c => wrapFunctional(c.default || c))
export const PesaroMuseum = () => import('../../components/PesaroMuseum.vue' /* webpackChunkName: "components/pesaro-museum" */).then(c => wrapFunctional(c.default || c))
export const PostItem = () => import('../../components/PostItem.vue' /* webpackChunkName: "components/post-item" */).then(c => wrapFunctional(c.default || c))
export const ProductChecked = () => import('../../components/ProductChecked.vue' /* webpackChunkName: "components/product-checked" */).then(c => wrapFunctional(c.default || c))
export const SimpleBanner = () => import('../../components/SimpleBanner.vue' /* webpackChunkName: "components/simple-banner" */).then(c => wrapFunctional(c.default || c))
export const SocialHead = () => import('../../components/SocialHead.vue' /* webpackChunkName: "components/social-head" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const StoreInfoWindow = () => import('../../components/StoreInfoWindow.vue' /* webpackChunkName: "components/store-info-window" */).then(c => wrapFunctional(c.default || c))
export const TabStoreInfoCards = () => import('../../components/TabStoreInfoCards.vue' /* webpackChunkName: "components/tab-store-info-cards" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const UnSubscribeSuccess = () => import('../../components/UnSubscribeSuccess.vue' /* webpackChunkName: "components/un-subscribe-success" */).then(c => wrapFunctional(c.default || c))
export const UserDropdown = () => import('../../components/UserDropdown.vue' /* webpackChunkName: "components/user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const VueSelectAjax = () => import('../../components/VueSelectAjax.vue' /* webpackChunkName: "components/vue-select-ajax" */).then(c => wrapFunctional(c.default || c))
export const WhatsappChat = () => import('../../components/WhatsappChat.vue' /* webpackChunkName: "components/whatsapp-chat" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/accordion/Item.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AccordionList = () => import('../../components/accordion/List.vue' /* webpackChunkName: "components/accordion-list" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaAisSearchState = () => import('../../components/algolia/AisSearchState.vue' /* webpackChunkName: "components/algolia-ais-search-state" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaColorRefinementList = () => import('../../components/algolia/ColorRefinementList.vue' /* webpackChunkName: "components/algolia-color-refinement-list" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaLoadingHits = () => import('../../components/algolia/LoadingHits.vue' /* webpackChunkName: "components/algolia-loading-hits" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaLoadingIndicator = () => import('../../components/algolia/LoadingIndicator.vue' /* webpackChunkName: "components/algolia-loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaNoResults = () => import('../../components/algolia/NoResults.vue' /* webpackChunkName: "components/algolia-no-results" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaPanel = () => import('../../components/algolia/Panel.vue' /* webpackChunkName: "components/algolia-panel" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaProductHits = () => import('../../components/algolia/ProductHits.vue' /* webpackChunkName: "components/algolia-product-hits" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaRangeRefinementList = () => import('../../components/algolia/RangeRefinementList.vue' /* webpackChunkName: "components/algolia-range-refinement-list" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaSquareRefinementList = () => import('../../components/algolia/SquareRefinementList.vue' /* webpackChunkName: "components/algolia-square-refinement-list" */).then(c => wrapFunctional(c.default || c))
export const IconsCalander = () => import('../../components/icons/Calander.vue' /* webpackChunkName: "components/icons-calander" */).then(c => wrapFunctional(c.default || c))
export const IconsContactNumberIcon = () => import('../../components/icons/ContactNumberIcon.vue' /* webpackChunkName: "components/icons-contact-number-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsContractIcon = () => import('../../components/icons/ContractIcon.vue' /* webpackChunkName: "components/icons-contract-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCopyIcon = () => import('../../components/icons/CopyIcon.vue' /* webpackChunkName: "components/icons-copy-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDrawer = () => import('../../components/icons/Drawer.vue' /* webpackChunkName: "components/icons-drawer" */).then(c => wrapFunctional(c.default || c))
export const IconsEmpty = () => import('../../components/icons/Empty.vue' /* webpackChunkName: "components/icons-empty" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebook = () => import('../../components/icons/Facebook.vue' /* webpackChunkName: "components/icons-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsInstagram = () => import('../../components/icons/Instagram.vue' /* webpackChunkName: "components/icons-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsLinkedIn = () => import('../../components/icons/LinkedIn.vue' /* webpackChunkName: "components/icons-linked-in" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/icons/Phone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsScreenIcon = () => import('../../components/icons/ScreenIcon.vue' /* webpackChunkName: "components/icons-screen-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTikTok = () => import('../../components/icons/TikTok.vue' /* webpackChunkName: "components/icons-tik-tok" */).then(c => wrapFunctional(c.default || c))
export const IconsTwitter = () => import('../../components/icons/Twitter.vue' /* webpackChunkName: "components/icons-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsUsersIcon = () => import('../../components/icons/UsersIcon.vue' /* webpackChunkName: "components/icons-users-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsYouTube = () => import('../../components/icons/YouTube.vue' /* webpackChunkName: "components/icons-you-tube" */).then(c => wrapFunctional(c.default || c))
export const MapsBaiduMap = () => import('../../components/maps/BaiduMap.vue' /* webpackChunkName: "components/maps-baidu-map" */).then(c => wrapFunctional(c.default || c))
export const MapsGoogleMap = () => import('../../components/maps/GoogleMap.vue' /* webpackChunkName: "components/maps-google-map" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentMethodFactory = () => import('../../components/payments/PaymentMethodFactory.vue' /* webpackChunkName: "components/payments-payment-method-factory" */).then(c => wrapFunctional(c.default || c))
export const ProductsNotFound = () => import('../../components/products/NotFound.vue' /* webpackChunkName: "components/products-not-found" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductFaqs = () => import('../../components/products/ProductFaqs.vue' /* webpackChunkName: "components/products-product-faqs" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductFindDealer = () => import('../../components/products/ProductFindDealer.vue' /* webpackChunkName: "components/products-product-find-dealer" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductGallery = () => import('../../components/products/ProductGallery.vue' /* webpackChunkName: "components/products-product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductGeometries = () => import('../../components/products/ProductGeometries.vue' /* webpackChunkName: "components/products-product-geometries" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductIntroduction = () => import('../../components/products/ProductIntroduction.vue' /* webpackChunkName: "components/products-product-introduction" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductInventories = () => import('../../components/products/ProductInventories.vue' /* webpackChunkName: "components/products-product-inventories" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductManual = () => import('../../components/products/ProductManual.vue' /* webpackChunkName: "components/products-product-manual" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductNavSection = () => import('../../components/products/ProductNavSection.vue' /* webpackChunkName: "components/products-product-nav-section" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductSpecification = () => import('../../components/products/ProductSpecification.vue' /* webpackChunkName: "components/products-product-specification" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTab = () => import('../../components/products/ProductTab.vue' /* webpackChunkName: "components/products-product-tab" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTopFeatures = () => import('../../components/products/ProductTopFeatures.vue' /* webpackChunkName: "components/products-product-top-features" */).then(c => wrapFunctional(c.default || c))
export const ReviewsioProductRatingSnippet = () => import('../../components/reviewsio/ProductRatingSnippet.vue' /* webpackChunkName: "components/reviewsio-product-rating-snippet" */).then(c => wrapFunctional(c.default || c))
export const ShippingMethod = () => import('../../components/shipping/ShippingMethod.vue' /* webpackChunkName: "components/shipping-method" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsBankTransfer = () => import('../../components/payments/methods/BankTransfer.vue' /* webpackChunkName: "components/payments-methods-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsCashPayment = () => import('../../components/payments/methods/CashPayment.vue' /* webpackChunkName: "components/payments-methods-cash-payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsCheckPayment = () => import('../../components/payments/methods/CheckPayment.vue' /* webpackChunkName: "components/payments-methods-check-payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsDatafast = () => import('../../components/payments/methods/Datafast.vue' /* webpackChunkName: "components/payments-methods-datafast" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsDatafastBridge = () => import('../../components/payments/methods/DatafastBridge.vue' /* webpackChunkName: "components/payments-methods-datafast-bridge" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsDecidir = () => import('../../components/payments/methods/Decidir.vue' /* webpackChunkName: "components/payments-methods-decidir" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsMercadoPago = () => import('../../components/payments/methods/MercadoPago.vue' /* webpackChunkName: "components/payments-methods-mercado-pago" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsMobbex = () => import('../../components/payments/methods/Mobbex.vue' /* webpackChunkName: "components/payments-methods-mobbex" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsPayPhone = () => import('../../components/payments/methods/PayPhone.vue' /* webpackChunkName: "components/payments-methods-pay-phone" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMethodsStripeTDC = () => import('../../components/payments/methods/StripeTDC.vue' /* webpackChunkName: "components/payments-methods-stripe-t-d-c" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
