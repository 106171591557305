import VuexPersistence from 'vuex-persist'

export default ({ app, store, isDev, $config }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      key: $config.APP_COOKIE_NAME,
      modules: ['app'],
      restoreState: (key, storage) => app.$cookies.get(key),
      saveState: (key, state, storage) => {
        return app.$cookies.set(key, state, {
          secure: !isDev,
          //   httpOnly: !isDev,
          // path: '/',
          sameSite: 'lax'
        })
      }
    }).plugin(store)
  })
}
