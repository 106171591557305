export default function ({ app, route, store, $config, error }) {
  if (
    store.state.currentSite &&
    store.state.currentSite.settings &&
    !store.state.currentSite.settings.test_ride_inquiry &&
    route.path.includes("test-drive")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    store.state.currentSite &&
    store.state.currentSite.settings &&
    !store.state.currentSite.settings.become_a_partner &&
    route.path.includes("become-a-dealer")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    store.state.currentSite &&
    store.state.currentSite.settings &&
    !store.state.currentSite.settings.work_with_us &&
    route.path.includes("work-with-us")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
}
