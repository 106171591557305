export const state = () => ({
  init: false,
  loading: true,
  items: [],
  total: 0,
  subtotal: 0,
  taxes: [],
  uuid: ''
})

export const actions = {
  fetchCartItems ({ commit, dispatch, state, getters }) {
    commit('SET_LOADING', true)
    commit('INIT')
    this.$axios
      .$get('/api/front/cart', {
        headers: {
          'X-Cart': this.$cookies.get(this.$config.CART_COOKIE_NAME)
        }
      })
      .then(({ data }) => {
        commit('SET_LOADING', false)
        commit('SET_CART_ITEMS', data.items)
        commit('SET_CART_TOTAL', data.total)
        commit('SET_CART_SUBTOTAL', data.subtotal)
        commit('SET_CART_TAXES', data.taxes)
        commit('SET_CART_UUID', data.uuid)
      })
      .catch((e) => {
        commit('SET_LOADING', false)

        console.error('[cart] load error', e)
      })
  },
  addCartItem ({ state, dispatch, commit }, { uuid, cb }) {
    const cartItem = state.items.find(item => item.uuid === uuid)

    if (!cartItem) {
      return this.$axios
        .$post(
          '/api/front/cart/items',
          { uuid },
          {
            headers: {
              'X-Cart': this.$cookies.get(this.$config.CART_COOKIE_NAME)
            }
          }
        )
        .then(() => {
          if (cb) {
            cb()
          }
          dispatch('fetchCartItems')
        })
    }
  },
  removeCartItem ({ state, dispatch, commit }, uuid) {
    const cartItem = state.items.find(item => item.uuid === uuid)

    if (cartItem) {
      this.$axios
        .$delete(`/api/front/cart/items/${uuid}`, {
          headers: {
            'X-Cart': this.$cookies.get(this.$config.CART_COOKIE_NAME)
          }
        })
        .then(() => {
          dispatch('fetchCartItems')
        })
    }
  },
  checkout ({ state, dispatch }) {
    return this.$axios
      .$post(
        '/api/front/cart/check-out',
        {},
        {
          headers: {
            'X-Cart': this.$cookies.get(this.$config.CART_COOKIE_NAME)
          }
        }
      )
      .then(({ data }) => {
        dispatch('checkout/setSalesOrder', data, { root: true })
      })
      .catch(() => {
        console.error('[cart] checkout error')
      })
  }
}

export const mutations = {
  INIT (state) {
    state.init = true
  },
  SET_CART_ITEMS (state, items) {
    state.items = items
  },
  SET_CART_TOTAL (state, total) {
    state.total = total
  },
  SET_CART_TAXES (state, taxes) {
    state.taxes = taxes
  },
  SET_CART_SUBTOTAL (state, subtotal) {
    state.subtotal = subtotal
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_CART_UUID (state, uuid) {
    state.uuid = uuid
  }
}

export const getters = {
  getUUID: state => state.uuid,
  getLoading: state => state.loading,
  getItems: state => state.items,
  getTotal: state => state.total,
  getTaxes: state => state.taxes,
  getSubTotal: state => state.subtotal,
  getNumberOfItems: state => (state.items ? state.items.length : 0)
}
