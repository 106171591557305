const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['block-route'] = require('../middleware/block-route.js')
middleware['block-route'] = middleware['block-route'].default || middleware['block-route']

middleware['cart-cookie-validation'] = require('../middleware/cart-cookie-validation.js')
middleware['cart-cookie-validation'] = middleware['cart-cookie-validation'].default || middleware['cart-cookie-validation']

middleware['check-country'] = require('../middleware/check-country.js')
middleware['check-country'] = middleware['check-country'].default || middleware['check-country']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
