export default ({ store, $config }) => {
  // if (store.state.country === 'ec') {
  const script = document.createElement('script')
  const storeData = store.getters.getCurrentSite
  if (storeData.settings.google_analytics && storeData.settings.google_analytics.integrate == true && storeData.settings.google_analytics.app_id) {
    const dataID = storeData.settings.google_analytics.app_id
    script.src = `https://www.googletagmanager.com/gtag/js?id=${dataID}`
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script)
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', dataID)
  }
  if (storeData.country.country_code == "it") {
    const dataID = "AW-16580415221";
    script.src = `https://www.googletagmanager.com/gtag/js?id=${dataID}`;
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", dataID);
    gtag("event", "conversion", {
      send_to: "AW-16580415221/mj2NCN2vp7UZEPWdlOI9",
    });
  }
}