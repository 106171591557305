import Vue from 'vue'
import VModal from 'vue-js-modal'

export default (context) => {
  Vue.use(VModal, {
    dynamic: true,
    transition: 'fade',
    dynamicDefaults: {
      width: '90%',
      maxWidth: 600,
      clickToClose: true,
      scrollable: true,
      height: 'auto',
      adaptive: true
      //   root: context
    },
    injectModalsContainer: true
  })
}
