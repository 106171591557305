const Intercom = require('~/utils/intercom.js').default

const intercomInstalled = false
let $intercom = null

export default ({ app, store, $config }, inject) => {
  if (!store.state.country) {
    return
  }

  if (!store.state.currentSite.settings.intercom) {
    return
  }

  if (!store.state.currentSite.settings.intercom.integrate) {
    return
  }

  if (intercomInstalled) {
    return
  }

  const APP_ID =
    store.state.currentSite.settings.intercom.app_id !== null
      ? store.state.currentSite.settings.intercom.app_id
      : `${$config.INTERCOM_APP_ID}`

  $intercom = new Intercom(APP_ID, { debug: false })

  inject('intercom', $intercom)

  if (typeof window.Intercom === 'function') {
    $intercom.init()
    $intercom.call('reattach_activator')
    $intercom.update()
    inject('loadIntercom', null)
  } else {
    // eslint-disable-next-line no-var
    window.Intercom = createIntercomPlaceholder()

    // const callWhenIntercomScriptLoaded = initialiseIntercom(ctx, APP_ID)
    const callWhenIntercomScriptLoaded = () => {
      $intercom.init()
      $intercom.boot({ app_id: APP_ID })
    }

    const callWhenPageLoaded = () =>
      includeIntercomScript(APP_ID, callWhenIntercomScriptLoaded)

    inject('loadIntercom', callWhenPageLoaded)
  }
}

function createIntercomPlaceholder () {
  const placeholder = (...args) => placeholder.c(args)
  placeholder.queue = []
  placeholder.c = args => placeholder.queue.push(args)
  return placeholder
}

function includeIntercomScript (appId, callback) {
  const intercomScript = document.createElement('script')
  intercomScript.async = true
  intercomScript.src = `https://widget.intercom.io/widget/${appId}`
  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode.insertBefore(intercomScript, firstScript)

  intercomScript.addEventListener('load', callback)
}

// function callWhenPageLoaded (callback) {
//   console.log(callback)
//   if (window.attachEvent) {
//     window.attachEvent('onload', callback)
//   } else {
//     window.addEventListener('load', callback, false)
//   }
// }
