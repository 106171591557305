import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement('i', {
        class: 'far fa-times text-gray-500 mr-2'
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement('i', {
        class: 'far fa-angle-down text-gray-500 mr-2'
      })
  }
})

Vue.component('vue-select', vSelect)
