export default ({ app }, inject) => {
  const loadBaiduMap = () => {
    return new Promise((resolve, reject) => {
      if (typeof window.BMap !== 'undefined') {
        resolve(window.BMap);
        return true;
      }
      window.initMap = () => {
        const markerClustererScript = document.createElement('script');
        markerClustererScript.src = 'https://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js';
        markerClustererScript.onload = () => {
          const textIconOverlayScript = document.createElement('script');
          textIconOverlayScript.src = 'https://api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js';
          textIconOverlayScript.onload = () => {
            resolve(window.BMap);
          };
          textIconOverlayScript.onerror = reject;
          document.head.appendChild(textIconOverlayScript);
        };
        markerClustererScript.onerror = reject;
        document.head.appendChild(markerClustererScript);
      };
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://api.map.baidu.com/api?v=2.0&ak=4WQ1IhfmmiNmjujo992AbxrePwPvgEdc&callback=initMap`;
      script.onerror = reject;
      document.head.appendChild(script);
      
    });
  };

  inject('loadBaiduMap', loadBaiduMap);
};
// export default ({ $config, isHMR, store }, inject) => {
//   // In case of HMR, mutation occurs before nuxReady, so previously saved state
//   // gets replaced with original state received from server. So, we've to skip HMR.
//   // Also nuxtReady event fires for HMR as well, which results multiple registration of
//   // any vuex persisted state plugin
//   if (isHMR) {
//     return
//   }

//   if (!process.browser) {
//     return
//   }

//   const $loadBaiduMap = resolve => new Promise((resolve, reject) => {
//     window.initMap = resolve
//     // const script = document.createElement('script')
//     // script.src = 'https://api.map.baidu.com/api?v=2.0&ak=4WQ1IhfmmiNmjujo992AbxrePwPvgEdc&callback=initMap'
//     ;[
//       'https://api.map.baidu.com/api?v=2.0&ak=4WQ1IhfmmiNmjujo992AbxrePwPvgEdc&callback=initMap',
//       'https://api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js',
//        'https://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js'
//     ]
//       .forEach((src) => {
//         const script = document.createElement('script')
//         script.src = src
//         document.head.appendChild(script)
//       })
//   })

//   inject('loadBaiduMap', $loadBaiduMap)
// }
