export const state = () => ({
  salesOrder: {},
  selectedAddressType: null,
  selectedAddress: {},
  selectedPaymentMethod: {},
  paymentMethods: [],
  resultAction: {}
})

export const actions = {
  setSalesOrder ({ commit, state }, obj) {
    commit('SET_SALES_ORDER', obj)
  },
  getPaymentMethods ({ commit, state }) {
    return this.$axios
      .$get('/api/front/payment-methods')
      .then(({ data }) => {
        commit('SET_PAYMENT_METHODS', data)

        return data
      })
      .catch(() => {
        console.error('[checkout] payment methods load error')
      })
  },
  setSelectedPaymentMethod ({ commit, state }, pm) {
    commit('SET_SELECTED_PAYMENT_METHOD', pm)
  },
  setSelectedAddressType ({ commit, state }, type) {
    commit('SET_SELECTED_ADDRESS_TYPE', type)
  },
  setSelectedAddress ({ commit, state }, addr) {
    commit('SET_SELECTED_ADDRESS', addr)
  },
  checkout ({ commit, state }, data) {
    return this.$axios
      .$post(
        '/api/front/sales-orders/checkout',
        {
          payment_method_id:
            typeof data.payment_method_id !== 'undefined'
              ? data.payment_method_id
              : state.selectedPaymentMethod.id,
          check_terms: data.check_terms,
          address_id: data.address_id,
          address_type: data.address_type
        },
        {
          headers: {
            'X-Cart': this.$cookies.get(this.$config.CART_COOKIE_NAME)
          }
        }
      )
      .then(({ data }) => {
        commit('SET_RESULT_ACTION', data)
      })
  },
  get ({ state, commit }, code) {
    return this.$axios
      .$get(`api/front/sales-orders/${code}`, code)
      .then(({ data }) => {
        commit('SET_SALES_ORDER', data)
      })
      .catch(() => {
        console.error('[checkout] payment methods load error')
      })
  }
}

export const mutations = {
  SET_SALES_ORDER (state, obj) {
    state.salesOrder = obj
  },
  SET_SELECTED_PAYMENT_METHOD (state, obj) {
    state.selectedPaymentMethod = obj
  },
  SET_SELECTED_ADDRESS_TYPE (state, string) {
    state.selectedAddressType = string
  },
  SET_SELECTED_ADDRESS (state, obj) {
    state.selectedAddress = obj
  },
  SET_PAYMENT_METHODS (state, array) {
    state.paymentMethods = array
  },
  SET_RESULT_ACTION (state, obj) {
    state.resultAction = obj
  }
}

export const getters = {
  getSalesOrder: state => state.salesOrder,
  getSelectedPaymentMethod: state => state.selectedPaymentMethod,
  getSelectedAddressType: state => state.selectedAddressType,
  getSelectedAddress: state => state.selectedAddress,
  getPaymentMethods: state => state.paymentMethods,
  getResultAction: state => state.resultAction
}
