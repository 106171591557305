export default function ({
  isHMR,
  store,
  route,
  app,
  error,
  redirect,
  params,
}) {
  // In case of HMR, mutation occurs before nuxReady, so previously saved state
  // gets replaced with original state received from server. So, we've to skip HMR.
  // Also nuxtReady event fires for HMR as well, which results multiple registration of
  // any vuex persisted state plugin
  if (isHMR) {
    return;
  }

  // If request path are from index, ignore it

  if (["/", ""].includes(route.path)) {
    return;
  }

  if (!store.state.country && !route.path.includes("/gdc-countdown")) {
    let message = "This page could not be found (no-country).";
    let status = 404;

    if (store.state.maintenance) {
      message = "This page its under maintenance, please check again later.";
      status = 503;
    }

    return error({
      message,
      statusCode: status,
    });
  }

  if (!store.state.locale) {
    return error({
      message: "This page could not be found (no-locale).",
      statusCode: 404,
    });
  }
}
