import Vue from 'vue'

export const state = () => ({
  addresses: []
})

export const actions = {
  get ({ commit, state }, type) {
    return this.$axios
      .$get('/api/auth/addresses')
      .then(({ data }) => {
        let array = data

        if (type) {
          array = data.filter(address => (address.type = type))
        }
        commit('SET_ADDRESSES', array)

        return array
      })
      .catch(() => {
        console.error('[address] SET_ADDRESSES error')
      })
  },
  update ({ commit }, address) {
    return this.$axios
      .$put(`/api/auth/addresses/${address.id}`, address)
      .then(({ data }) => {
        commit('UPDATE_ADDRESSES', data)
      })
  },
  create ({ commit }, address) {
    return this.$axios
      .$post('/api/auth/addresses', address)
      .then(({ data }) => {
        commit('ADD_ADDRESSES', data)

        return data
      })
  },
  delete ({ commit }, address) {
    return this.$axios
      .$delete(`/api/auth/addresses/${address.id}`, address)

      .then(() => {
        commit('DELETE_ADDRESSES', address)
      })
      .catch(() => {
        console.error('[address] DELETE_ADDRESSES error')
      })
  },
  fulfillment ({ state }, data) {
    return this.$axios.$post('/api/front/fulfillment', data)
  }
}

export const mutations = {
  SET_ADDRESSES (state, addresses) {
    state.addresses = addresses
  },
  ADD_ADDRESSES (state, address) {
    state.addresses.push(address)
  },
  UPDATE_ADDRESSES (state, address) {
    const addressIndex = state.addresses.findIndex(a => address.id === a.id)

    if (addressIndex !== -1) {
      Vue.set(state.addresses, addressIndex, address)
    }
  },
  DELETE_ADDRESSES (state, address) {
    const addressIndex = state.addresses.findIndex(a => address.id === a.id)

    if (addressIndex !== -1) {
      state.addresses.splice(addressIndex, 1)
    }
  }
}

export const getters = {
  getAddresses: state => state.addresses
}
