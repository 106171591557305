export const state = () => ({
  user: null,
  loggedIn: false
})

export const mutations = {
  SET_USER (store, data) {
    store.user = data
    store.loggedIn = true
  },
  RESET_USER (store) {
    store.user = null
    store.loggedIn = false
  }
}

export const actions = {
  fetch ({ commit }) {
    return this.$axios
      .$get('/api/auth/user')
      .then(({ data }) => {
        commit('SET_USER', data)
        return data
      })
      .catch((error) => {
        commit('RESET_USER')

        return error
      })
  },
  login ({ commit, dispatch, rootState }, data) {
    return this.$axios
      .$post('/api/auth/login', data)
      .then((loginResponse) => {
        const token = loginResponse.data.access_token

        this.$cookies.set('AuthTkn', token, {
          expires: new Date(loginResponse.data.expires_at),
          secure: process.env.NODE_ENV === 'production',
          path: `/`,
          // path: `/${rootState.country}-${rootState.locale}`,
        })
        this.$cookies.set('expires_at', loginResponse.data.expires_at)
        return dispatch('fetch')
      })
  },
  logout ({ commit, rootState }) {
    commit('RESET_USER')

    this.$cookies.remove('AuthTkn', {
      path: `/`
    })
    this.$cookies.remove('AuthTkn', {
      path: `/${rootState.country}-${rootState.locale}`
    })

    return Promise.resolve()
  }
}

export const getters = {
  isAuthenticated (state) {
    return state.loggedIn
  },
  loggedUser (state) {
    return state.user
  }
}
