
export default ({ $config, isHMR, store }, inject) => {
  // In case of HMR, mutation occurs before nuxReady, so previously saved state
  // gets replaced with original state received from server. So, we've to skip HMR.
  // Also nuxtReady event fires for HMR as well, which results multiple registration of
  // any vuex persisted state plugin
  if (isHMR) {
    return
  }

  if (!process.browser) {
    return
  }

  const $loadGoogleMaps = resolve => new Promise((resolve, reject) => {
    window.initMap = resolve
    const mapId = 'bb21f8f7be75a8ab'

    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${$config.GOOGLE_API}&map_ids=${mapId}&libraries=geometry,places&language=${store.state.locale}&region=${store.state.country}&callback=initMap`
    script.defer = true

    document.head.appendChild(script)
  })

  inject('loadGoogleMaps', $loadGoogleMaps)
}
