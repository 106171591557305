export default ({ app, store, isHMR }) => {
  app.router.beforeEach((to, from, next) => {
    const forceReload = store.getters['app/getForceReload']

    // Force the page to reload when there is a new deploy
    if (forceReload) {
      console.info('🥐 🧉 new deployment: force reload')
      store.dispatch('app/setForceReload', false)
      window.location = to.fullPath
    }

    return next()
  })
}
