import Vue from "vue";
import i18next from "i18next";
import I18NextVue from "i18next-vue";

Vue.use(I18NextVue, { i18next });

export default function ({ store, app, params }, inject) {
  let translationData = store.state.translationData;

  i18next.init(translationData);

  // Link generation helper
  const generateLink = (route) => {
    let locale = i18next.language;
    let [country, pathlocale] = params.country.split("-");

    // Abort if no route or no locale
    if (!route) {
      return {};
    }

    country = country || store.state.country;
    locale = locale || store.state.locale;

    if (!country || !locale) {
      return {};
    }

    // If route parameter is a string, check if it's a path or name of route.
    if (typeof route === "string") {
      if (route[0] === "/") {
        // If route parameter is a path, create route object with path.
        route = { path: route };
      } else {
        // Else use it as route name.
        route = { name: route };
      }
    }

    const localizedRoute = Object.assign({}, route);

    // if route has a path defined but no name, resolve full route using the path
    if (route.path && !route.name) {
      const path = `/${country}-${locale}${route.path}`;

      localizedRoute.path = path;
    } else {
      // otherwise resolve route via the route name
      // Build localized route options
      localizedRoute.name = country + "-" + locale + "/" + route.name;

      const { params } = localizedRoute;
      if (params && params["0"] === undefined && params.pathMatch) {
        params["0"] = params.pathMatch;
      }
    }

    // Resolve localized route

    const {
      route: { fullPath },
    } = app.router.resolve(localizedRoute);

    return fullPath;
    // return `/${locale}/${route}`;
  };

  inject("i18nPath", generateLink);
}
